.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #fff;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 650px;
    width: 70%;
}

.icon {
    width: 20px;
    height: 20px;
    fill: var(--tertiary-color);
}