header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    width: 100%;
    height: 90px;
    background-color: var(--primary-color);
    padding-top: 15px;
    padding-bottom: 15px;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.name {
    height: 25px;
    display: flex;
    align-items: center;
    transition: 300ms;
    color: var(--tertiary-color);
}

.logo {
    height: 25px;
    margin-right: 10px;
}

.navigation {
    text-align: center;
    font-weight: 500;
}

.navigationList {
    display: flex;
    list-style: none;
}

.navigationListItem:not(:last-child) {
    margin-right: 12px;
}

.navigationLink {
    transition: 300ms;
    color: var(--tertiary-color);
}

.navigationLink:hover {
    color: var(--secondary-color);
}

.name:hover {
    color: var(--secondary-color);
}

@media (min-width: 768px) {
    header {
        height: 70px;
        padding: 15px;
    }

    .headerContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        height: 27px;
        margin-right: 12px;
    }
}

@media (min-width: 768px) {
    .nameText {
        font-size: 24px;
    }

    .navigationListItem:not(:last-child) {
        margin-right: 16px;
    }

}

@media (min-width: 1024px) {
    .headerContent {
        max-width: 1300px;
    }

    .logo {
        height: 36px;
        margin-right: 15px;
    }

    .name {
        line-height: 45px;
        margin-right: 10px;
    }
    .nameText {
        font-size: 28px;
    }

    .navigationListItem:not(:last-child) {
        margin-right: 25px;
    }

}