@import "normalize.css/normalize.css";
@import './roots/roots.css';

body {
  margin: 0;
  font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.4;
}

html {
  scroll-behavior: smooth;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

ul {
  padding: 0
}
h1 {
  font-size: 21px;
}

/* CSS for navigation*/
.div-nav {
  content: "";
  display: block;
  height: 90px;
  margin: -90px 0 0;
  z-index: 1;
}

h5::after {
  content: "";
  display: block;
  z-index: 1;
}

h5 {
  font-size: 0;
  margin-block-start: 0;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h3 {
  font-size: 1.5em;
}

/* CSS for swiper js */
.swiper-pagination {
  --swiper-theme-color: var(--primary-color)
}

.swiper-thumbs .swiper-wrapper .swiper-slide-thumb-active {
  opacity: 1;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

.swiper-button-next {
  --swiper-navigation-size: 30px;
}

.swiper-button-prev {
  --swiper-navigation-size: 30px;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    line-height: 1.5;
  }

  .div-nav {
    content: "";
    display: block;
    height: 70px;
    margin: -70px 0 0;
  }

  .swiper-button-next {
    --swiper-navigation-size: 50px;
  }

  .swiper-button-prev {
    --swiper-navigation-size: 50px;
  }
}

/* Animations */

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}