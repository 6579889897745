footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    background-color: var(--primary-color);
    padding: 15px 0px;
    color: var(--tertiary-color);
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    fill: #fff;
    font-weight: 500;
}
.icon {
    margin-left: 5px;
    height: 20px;
    width: 20px;
}

.link:hover {
    fill: var( --secondary-color);
    color: var( --secondary-color);
}


@media (min-width: 768px) {
    .link {
        font-size: 18px;
    }
}