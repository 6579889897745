.gallery {
    padding: 15px;
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
}

.swiper {
    margin-top: 25px;
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.swiper .swiperSlide {
    padding-top: 70%;
    overflow: hidden;
    position: relative;
}

.swiper .swiperSlide img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.swiperThumbs .swiperSlide {
    cursor: pointer;
    border: 1px solid #dddbdb;
    width: 25%;
    height: 100%;
    opacity: 0.6;
}

.swiperThumbs .swiperSlide img {
    object-fit: cover;
}

.swiperThumbs .swiper-wraper .swiper-slide-thumb-active {
    opacity: 1;
    border: 2px solid var(--primary-color);
}

.swiperThumbsWrapper {
    width: 100%;
    padding-top: 65%;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.swiperThumbsWrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.navLink {
    content: "";
    display: block;
    height: 90px;
    margin: -90px 0 0;
}

@media screen and (min-width: 768px) {
    .gallery {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .galleryHead {
        align-self: flex-start;
    }

    .swipers {
        max-width: 750px;
    }

    .navLink {
        content: "";
        display: block;
        height: 70px;
        margin: -70px 0 0;
    }
}

@media screen and (min-width: 1024px) {}