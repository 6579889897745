:root {
    --primary-color: #2a57aa;
    --secondary-color: #14326a;
    --tertiary-color:#fff; 
    
    /* root for swiper */
    --swiper-theme-color: var(--primary-color);

    --swiper-pagination-bullet-width: 12px;
    --swiper-pagination-bullet-height: 12px;;
    --swiper-pagination-bullet-horizontal-gap: 10px;

    --swiper-navigation-size: 30px;
  }