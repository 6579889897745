.box {
    height: 90px;
    background-color: var(--primary-color);
}

.backgroundImage {
    width: 100%;
    height: 400px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../images/bałtyk-mobile.jpeg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 90px);
}

.slogan {
    margin-top: -30px;
    color: #fff;
    text-align: center;
    padding: 15px;
    font-size: 26px;
}

.noWrap {
    white-space: nowrap;
}

@media (min-width: 768px) {

    .box {
        height: 70px;
    }

    .backgroundImage {
        height: calc(100vh - 70px);
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../images/bałtyk-tablet.jpeg);
    }

    .slogan {
        font-size: 40px;
        padding: 40px;
    }

    .noWrap::before {
        content: "\a";
        white-space: pre;
    }

}

@media (min-width: 1024px) {
    .backgroundImage {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../images/bałtyk.jpg);
    }

    .slogan {
        font-size: 46px;
    }
}